<!--
--------------------------------------------------------------------------------
<copyright file="BendingTimeBetweenBend.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.time_scale_s') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.time_betw_bends_txt1') }}</p>
      <p class="text">{{ $t('widget_info_text.time_betw_bends_txt2') }}:</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.time_betw_bends_simple') }}</span
          >:
          {{ $t('widget_info_text.time_betw_bends_simple_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.time_betw_bends_medium') }}</span
          >:
          {{ $t('widget_info_text.time_betw_bends_medium_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold caps">{{ $t('widget_info_text.time_betw_bends_complex') }}</span
          >:
          {{ $t('widget_info_text.time_betw_bends_complex_txt') }}
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.bars_txt_part_cat') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold" style="text-transform: capitalize">{{
            $t('widget_info_text.top_bar')
          }}</span>
          – <span class="blue-bar">{{ $t('widget_info_text.blue') }}</span> :
          {{ $t('widget_info_text.selected_period') }}
        </li>
        <li class="list-item">
          <span class="demi-bold" style="text-transform: capitalize">{{
            $t('widget_info_text.bottom_bar')
          }}</span>
          - <span class="green-bar">{{ $t('widget_info_text.green') }}</span
          >: {{ $t('widget_info_text.previous_period') }}
        </li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.bars_info_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
  },
})
export default class BendingTimeBetweenBend extends Vue {}
</script>
